<template>
  <section>
    <b-loading :active="uploadingInfos" :is-full-page="false"></b-loading>

    <section class="section has-background-eurotext-light">
      <div class="container">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            @submit.prevent="handleSubmit(uploadUserData)"
            @reset.prevent="resetForm"
          >
            <b-field class="is-flex is-align-items-stretch">
              <b-switch v-model="approveUserLater" left-label size="">
                <span class="has-text-light has-text-weight-bold">{{
                  $t(`policies.rao.approveLater.label`)
                }}</span>
              </b-switch>
              <span v-if="approveUserLater" class="has-text-light">
                {{ $t(`policies.rao.approveLater.blank_modify`) }}
              </span>
            </b-field>

            <user-data-mask
              v-model="infos"
              :organizationEnabled.sync="orgDataEnabled"
              :validate-username="true"
              :modifyLater="approveUserLater"
            ></user-data-mask>

            <div
              class="tile is-ancestor is-flex-wrap-wrap mt-5"
              v-show="!approveUserLater"
            >
              <div
                class="tile is-parent"
                v-for="(fileType, key) in files"
                :key="key"
                v-show="!fileType.hidden"
              >
                <div class="tile is-child box">
                  <b-upload-with-validation
                    v-model="fileType.files"
                    :rules="{ required: !!fileType.required }"
                    multiple
                  >
                    <template #label>
                      <span class="">{{
                        $t(`policies.rao.${key}.label`).concat(
                          fileType.required ? "*" : ""
                        )
                      }}</span>
                    </template>
                    <template #default>
                      <a class="button is-primary is-fullwidth">
                        <b-icon icon="text-box-plus"></b-icon>
                        <span>{{
                          $t("policies.upload.buttons.select_file")
                        }}</span>
                      </a>
                    </template>
                  </b-upload-with-validation>

                  <b-field
                    grouped
                    group-multiline
                    v-show="fileType.files && fileType.files.length > 0"
                  >
                    <div
                      class="control"
                      v-for="(file, index) in fileType.files"
                      :key="index"
                    >
                      <b-tag
                        type="is-primary-light"
                        close-type="is-primary"
                        attached
                        closable
                        aria-close-label="Close tag"
                        @close="removeFile(key, index)"
                      >
                        {{ file.name }}
                      </b-tag>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="buttons">
              <b-button
                type="is-primary"
                native-type="submit"
                icon-left="upload"
              >
                {{ $t("buttons.upload") }}
              </b-button>
              <b-button
                type="is-primary"
                native-type="reset"
                icon-left="eraser"
              >
                {{ $t("buttons.erase") }}
              </b-button>
              <!-- <b-button
                type="is-primary"
                native-type="reset"
                icon-left="eraser"
                @click="uploadFiles"
              >
                carica documenti
              </b-button> -->
            </div>
          </form>
        </ValidationObserver>
      </div>
    </section>
  </section>
</template>

<script>
// import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";

import { commonMixin } from "@/mixins";
// import { insuranceRoleList } from "@/helpers/constants";
// import Countries from "../../helpers/constants/countries";
// import BSelectWithValidation from "../../components/inputs/BSelectWithValidation.vue";
// import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
import { inputService, policiesService, raoServices } from "../../services";
import BUploadWithValidation from "../../components/inputs/BUploadWithValidation.vue";
import UserDataMask from "../../components/policies/users/UserDataMask";
import { createMetadata, getBase64FromFile } from "../../helpers";
import { formatISO, parse } from "date-fns";
// import { KCustomer } from "../../helpers/constants";
// import Cleave from "cleave.js";
// import { parseISO, formatISO, format } from "date-fns";

/**
 * We add a new instance of Cleave when the element
 * is bound and destroy it when it's unbound.
 */
// const cleave = {
//   name: "cleave",
//   bind(el, binding) {
//     const input = el.querySelector("input");
//     input._vCleave = new Cleave(input, binding.value || {});
//   },
//   update: (el) => {
//     const input = el.querySelector("input");
//     input.value = input._vCleave.properties.result;
//     console.log(input);
//     // const event = new Event("input", { bubbles: false });
//     // setTimeout(function () {
//     //   console.log(binding);
//     //   input.value = input._vCleave.properties.result;
//     //   input.dispatchEvent(event);
//     // }, 100);
//   },
//   unbind(el) {
//     const input = el.querySelector("input");
//     input._vCleave.destroy();
//   },
// };

// const dateRegex =
//   "^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$";
// const dateRegex = "[0-9]{2}-[0-9]{2}-[0-9]{4}";
const dateRegex = "[0-3]{1}[0-9]{1}/[0-1]{1}[0-9]{1}/[0-9]{4}";

function initialState() {
  return {
    dateRegex: dateRegex,
    uploadingInfos: false,
    infos: {
      role: {
        value: null,
        required: true,
        disabled: false,
      },
      isRAO: {
        value: null,
        required: true,
        disabled: false,
      },
      email: {
        value: null,
        required: true,
        disabled: false,
      },
      isEmailCertified: {
        value: null, // Boolean
        required: true,
        disabled: false,
      },
      mobileNumber: {
        value: "+39",
        required: true,
        disabled: false,
      }, //(completo di prefisso internazionale)
      fiscalCode: {
        value: "",
        required: true,
        disabled: false,
      },
      fiscalCountry: {
        value: "",
        required: true,
        disabled: false,
      },
      name: {
        value: "",
        required: true,
        disabled: false,
      },
      surname: {
        value: "",
        required: true,
        disabled: false,
      },
      birthDate: {
        value: null,
        required: true,
        type: "date",
        disabled: false,
      },
      birthPlace: {
        value: null,
        required: true,
        disabled: false,
      }, // Free text
      birthProvince: {
        value: null,
        required: true,
        disabled: false,
      }, // Free text
      birthCountry: {
        value: "",
        required: true,
        disabled: false,
      }, // country code
      documentType: {
        value: "NIC",
        required: true,
        disabled: false,
      }, //(default: NIC)
      documentId: {
        value: null,
        required: true,
        disabled: false,
      },
      organizationName: {
        value: null,
        required: false,
        disabled: false,
      },
      organizationCode: {
        value: null,
        required: false,
        disabled: false,
      },
      organizationCountry: {
        value: "",
        required: false,
        disabled: false,
      },
      organizationTypeTIN: {
        value: false,
        required: false,
        disabled: false,
      },
      recognitionType: {
        value: "DE_VISU",
        required: true,
        disabled: false,
      }, //(default: DE_VISU)
      domainUsername: {
        value: "",
        required: true,
        disabled: false,
      },
      crpDeliveryMode: {
        value: "EMAIL",
        required: true,
        disabled: false,
      },
      serviceMode: {
        value: "SILVER",
        required: true,
        disabled: false,
      },
      barcodeFormat: {
        value: "NONE",
        required: true,
        disabled: false,
      },
      isApproved: {
        value: true,
        required: true,
        disabled: false,
      },
    },
    files: {
      identificationFile: { files: null, required: true },
      fiscalCodeFile: { files: null, hidden: false },
      imageFile: { files: null, hidden: false },
      oraganizationFile: { files: null, hidden: false },
      otherFiles: { files: null, hidden: false },
    },
    orgDataEnabled: false,
    approveUserLater: false,
    // formattedBirthDate: "",
    // roleList: insuranceRoleList,
    // countries: Countries,
    // recognitionTypeList: [
    //   "NONE",
    //   "BANK_TRANSFER",
    //   "WEBCAM",
    //   "DE_VISU",
    //   "EIDAS_SIGNATURE",
    // ],
    // masks: {
    //   date: {
    //     date: true,
    //     delimiter: "-",
    //     datePattern: ["d", "m", "Y"],
    //   },
    // },
  };
}
export default {
  // directives: { cleave },
  mixins: [commonMixin],
  components: {
    ValidationObserver,
    // ValidationProvider,
    // BSelectWithValidation,
    // BInputWithValidation,
    BUploadWithValidation,
    UserDataMask,
  },
  data() {
    return initialState();
  },
  computed: {
    // filteredBirthCountries() {
    //   return this.filterCountries(this.infos.birthCountry.value);
    // },
    // filteredOrgCountryList() {
    //   return this.filterCountries(this.infos.organizationCountry.value);
    // },
    // filteredFiscalCountries() {
    //   return this.filterCountries(this.infos.fiscalCountry.value);
    // },
    // formattedBirthDate: {
    //   get() {
    //     if (this.infos.birthDate)
    //       return format(parseISO(this.infos.birthDate), "dd-MM-yyyy");
    //     else return this.infos.birthDate;
    //   },
    //   set(val) {
    //     const date = formatISO(parseISO(val), "yyyy-MM-dd");
    //     this.infos.birthDate = date;
    //   },
    // },
  },
  watch: {
    // "infos.role.value": function (newVal, oldVal) {
    //   if (newVal === KCustomer.name) {
    //     this.infos.isRAO.value = false;
    //   } else if (oldVal === KCustomer.name) {
    //     this.infos.isRAO.value = null;
    //   }
    // },
    orgDataEnabled(newVal) {
      if (!newVal) {
        this.infos.organizationCode.value = null;
        this.infos.organizationCode.required = false;

        this.infos.organizationName.value = null;
        this.infos.organizationName.required = false;

        this.infos.organizationCountry.value = "";
        this.infos.organizationCountry.required = false;

        this.files.oraganizationFile.hidden = true;
        this.files.oraganizationFile.files = null;
        this.files.oraganizationFile.required = false;
      } else {
        this.files.oraganizationFile.hidden = false;
        this.files.oraganizationFile.required = true;

        this.infos.organizationCountry.required = true;
        this.infos.organizationName.required = true;
        this.infos.organizationCode.required = true;
      }
    },
    approveUserLater(newVal) {
      if (newVal) {
        this.infos.isApproved.value = false;
        this.infos.birthDate.required = false;
        this.infos.birthPlace.required = false;
        this.infos.birthCountry.required = false;
        this.infos.birthProvince.required = false;
        this.infos.documentType.value = null;
        this.infos.documentType.required = false;
        this.infos.documentId.value = null;
        this.infos.documentId.required = false;
        this.infos.recognitionType.value = null;
        this.infos.recognitionType.required = false;
        this.infos.recognitionType.disabled = true;
        for (let key of Object.keys(this.files)) {
          let file = this.files[key];
          file.required = false;
        }
      } else {
        this.infos.isApproved.value = true;
        this.infos.birthDate.required = true;
        this.infos.birthPlace.required = true;
        this.infos.birthCountry.required = true;
        this.infos.birthProvince.required = true;
        this.infos.documentType.value = "NIC";
        this.infos.documentType.required = true;
        this.infos.documentId.value = null;
        this.infos.documentId.required = true;
        this.infos.recognitionType.value = "DE_VISU";
        this.infos.recognitionType.required = true;
        this.infos.recognitionType.disabled = false;

        this.files.identificationFile.required = true;
      }
    },
  },
  methods: {
    // filterCountries(filter) {
    //   if (filter) {
    //     return Countries.filter((option) => {
    //       return option.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    //     });
    //   } else {
    //     return Countries;
    //   }
    // },
    removeFile(fileType, index) {
      this.files[fileType].files.splice(index, 1);
    },
    // onOrgDataToggle(newVal) {
    //   if (!newVal) {
    //     this.infos.organizationCountry.value = null;
    //     this.infos.organizationName.value = null;
    //     this.infos.organizationCountry.value = null;
    //     this.files.oraganizationFile.hidden = true;
    //     this.files.oraganizationFile.files = null;
    //   } else {
    //     this.files.oraganizationFile.hidden = false;
    //   }
    // },
    onBirthDateInput(event) {
      console.log(event);
      this.formattedBirthDate = event.target._vCleave.getFormattedValue();
      this.infos.birthDate = event.target._vCleave.getISOFormatDate();
    },
    async uploadUserData() {
      try {
        this.uploadingInfos = true;
        const arr = Object.keys(this.infos).map((key) => {
          let val = null;
          if (this.infos[key].value !== null && this.infos[key].value !== "") {
            if (this.infos[key].type === "date") {
              val = formatISO(
                parse(this.infos[key].value, "dd/MM/yyyy", new Date()),
                { representation: "date" }
              );
            } else {
              val = this.infos[key].value;
            }
          }
          return [key, val];
        });
        const userData = Object.fromEntries(arr);
        if (userData.organizationCode && userData.organizationCountry) {
          let prefix = "VAT";
          if (userData.organizationTypeTIN) {
            prefix = "TIN";
          }
          userData.organizationCode = `${prefix}${userData.organizationCountry}-${userData.organizationCode}`;
        }
        // const userData = Object.fromEntries(arr);
        console.log(userData);
        const recType = userData.recognitionType;

        // Load at first the user without approving it. If all is ok we will approve it at the end.
        userData.isApproved = false;
        userData.recognitionType = null;

        await policiesService.raoCreateUser(userData);

        this.$buefy.toast.open({
          message: this.$t("policies.upload.success.user_metadata"),
          type: "is-success",
          duration: 4000,
        });

        const fileSuccess = await this.uploadFiles();

        if (!fileSuccess) {
          this.$buefy.dialog.alert({
            message: this.$t("policies.rao.error.file_not_uploaded"),
            type: "is-danger",
            hasIcon: true,
            icon: "alert-circle",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          throw new Error("File not uploaded");
        }

        if (!this.approveUserLater) {
          userData.isApproved = true;
          userData.recognitionType = recType;
          userData.domainUsername = `${this.$store.getters["users/getSubRA"]}:${userData.domainUsername}`;
          userData.raoDomainUsername = this.$store.state.users.subNickname;

          await raoServices.approveUser(userData);
        }

        this.resetForm();
      } catch (e) {
        console.log(e);
      } finally {
        this.uploadingInfos = false;
      }
    },
    async uploadFiles() {
      try {
        for (let key in this.files) {
          const files = this.files[key].files;
          if (files) {
            for (let file of files) {
              console.log(file);
              const b64 = await getBase64FromFile(file);
              // const sha256 = await hexSHA256FromFile(file);
              const mime = file.type;
              const fileRes = await inputService.uploadFile(
                this.companyName,
                b64,
                mime,
                null //sha256
              );
              let docTypeStr = this.$t(`policies.rao.${key}.label`);
              if (key === "identificationFile") {
                docTypeStr = this.$t(
                  `policies.rao.documentType.${this.infos.documentType.value}`
                );
              }
              const properties = [
                {
                  name: "progressive",
                  value: 0,
                },
                {
                  name: "codice_fiscale",
                  value: this.infos.fiscalCode.value,
                },
                {
                  name: "cognome",
                  value: this.infos.surname.value,
                },
                {
                  name: "nome",
                  value: this.infos.name.value,
                },
                {
                  name: "sign_customer",
                  value: this.infos.domainUsername.value,
                },
                {
                  name: "username",
                  value: this.$store.state.users.subNickname,
                },
                {
                  name: "description",
                  value: docTypeStr,
                },
                {
                  name: "source_filename",
                  value: file.name,
                },
              ];
              // const className = this.$config.insurance.user_document_class;
              const className = `${this.$store.getters["users/getRASubRA"]}:${this.$config.insurance.user_document_class}`;
              const metadata = createMetadata(
                properties,
                className,
                file.type,
                fileRes["original_path"],
                fileRes["document_id"]
              );
              console.log(metadata);
              const response = await inputService.uploadMetadata(
                this.companyName,
                className,
                metadata,
                0,
                0
              );

              if (!response.error) {
                // Notify user
                this.$buefy.toast.open({
                  message: this.$t("policies.upload.success.file_loaded", {
                    file: file.name,
                  }),
                  type: "is-success",
                  duration: 3000,
                  queue: false,
                });
              } else {
                const text = this.$t(
                  "policies.upload.error.while_loading_file",
                  { file: file.name }
                );
                // Notify user
                this.$buefy.toast.open({
                  message: text,
                  type: "is-danger",
                  duration: 4000,
                  queue: false,
                });

                throw response;
              }
            }
          }
        }
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    resetForm() {
      this.$refs.observer.reset();
      Object.assign(this.$data, initialState());
    },
  },
};
</script>

<style></style>
