<template>
  <section class="section">
    <div class="container">
      <div
        class="columns is-centered is-multiline"
        v-if="configuredEmails.length > 0"
      >
        <div
          class="column"
          v-for="mail in configuredEmails"
          :key="mail.address"
        >
          <div class="box has-background-eurotext-light has-text-white-bis">
            <h2
              class="title is-6 has-text-white-bis has-text-weight-bold has-text-centered"
            >
              {{ $t("password_change.change_generic_password_title") }}
            </h2>
            <PasswordChangeForm
              @change-password="
                (...args) => changeUserPassword(...args, mail.type)
              "
              label-class="has-text-weight-normal has-text-white-bis"
            >
              <div class="block has-text-centered">
                <p class="tag is-size-6">{{ mail.address }}</p>
              </div>
            </PasswordChangeForm>
          </div>
          <b-loading
            :is-full-page="false"
            v-model="loadingPassword"
          ></b-loading>
        </div>
      </div>

      <div
        class="columns is-centered is-multiline"
        v-if="initializationEmails.length > 0"
      >
        <div
          class="column"
          v-for="mail in initializationEmails"
          :key="mail.address"
        >
          <div class="box has-background-eurotext-light has-text-white-bis">
            <h2
              class="title is-6 has-text-white-bis has-text-weight-bold has-text-centered"
            >
              {{ $t("policies.email_settings.activation.header") }}
            </h2>

            <registration-form
              label-class="has-text-weight-normal has-text-white-bis"
              @register="initMailBox($event, mail.type)"
            >
              <template #beforeFields>
                <div class="block has-text-centered">
                  <p class="tag is-size-6">{{ mail.address }}</p>
                </div>
              </template>

              <template #buttonLabel>
                {{ $t("buttons.activate") }}
              </template>
            </registration-form>
          </div>
          <b-loading
            :is-full-page="false"
            v-model="loadingPassword"
          ></b-loading>
        </div>
      </div>

      <!-- <div class="box has-background-eurotext-light has-text-white-bis"></div> -->

      <div
        v-if="clickRequired !== undefined"
        class="box has-background-eurotext-light has-text-white-bis"
      >
        <b-loading
          :is-full-page="false"
          v-model="loadingToggleClick"
        ></b-loading>
        <b-field class="has-text-centered">
          <template #label>
            <span class="title is-6 has-text-weight-bold has-text-white-bis">
              {{ $t("policies.email_settings.toggle_user_required.label") }}
            </span>
          </template>
          <b-checkbox
            v-model="clickRequired"
            size=""
            @input="toggleClickRequired"
            class="tag is-size-6"
          >
            {{
              clickRequired
                ? $t("policies.email_settings.toggle_user_required.active")
                : $t("policies.email_settings.toggle_user_required.not_active")
            }}
          </b-checkbox>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>
import { policiesService, responseSchemas } from "../../services";
import { commonMixin } from "@/mixins";
import PasswordChangeForm from "@/components/PasswordChangeForm";
import RegistrationForm from "../../components/RegistrationForm.vue";

export default {
  mixins: [commonMixin],
  components: {
    PasswordChangeForm,
    RegistrationForm,
  },
  data() {
    return {
      loadingSettings: false,
      loadingPassword: false,
      loadingToggleClick: false,
      clickRequired: undefined,
      mailboxes: [],
    };
  },
  computed: {
    configuredEmails() {
      return this.mailboxes.filter((mail) => mail.configured);
    },
    initializationEmails() {
      return this.mailboxes.filter((mail) => !mail.configured);
    },
  },
  methods: {
    async fetchAllSettings() {
      try {
        this.loadingSettings = true;
        const res = await policiesService.fetchAllSettings(this.companyName);
        this.clickRequired =
          res[responseSchemas.fetchAllSettings.clickRequired];
        this.mailboxes = res[responseSchemas.fetchAllSettings.mailboxes];
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingSettings = false;
      }
    },
    changeUserPassword(oldPassword, newPassword, emailType) {
      this.loadingPassword = true;
      policiesService
        .changeEmailPassword(
          this.companyName,
          oldPassword,
          newPassword,
          emailType
        )
        .then((data) => {
          if (data) {
            this.$buefy.toast.open({
              message: this.$t("messages.password_updated_success"),
              type: "is-success",
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t("error.wrong_password"),
              type: "is-danger",
            });
          }
        })
        .finally(() => (this.loadingPassword = false));
    },
    initMailBox(payload, emailType) {
      const { username, password } = payload;
      this.loadingPassword = true;
      policiesService
        .initMailBox(this.companyName, username, password, emailType)
        .then((data) => {
          if (data) {
            this.$buefy.toast.open({
              message: this.$t(
                "policies.email_settings.messages.activation_success"
              ),
              type: "is-success",
            });
            this.fetchAllSettings();
          } else {
            this.$buefy.toast.open({
              message: this.$t(
                "policies.email_settings.messages.activation_error"
              ),
              type: "is-danger",
            });
          }
        })
        .finally(() => (this.loadingPassword = false));
    },
    toggleClickRequired(value) {
      this.loadingToggleClick = true;
      policiesService
        .toggleUserClickRequired(this.companyName, value)
        .then((data) => {
          this.$buefy.toast.open({
            message: this.$t(
              "policies.email_settings.toggle_user_required.messages.success"
            ),
            type: "is-success",
          });
          this.clickRequired =
            data[responseSchemas.fetchAllSettings.clickRequired];
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t(
              "policies.email_settings.toggle_user_required.messages.error"
            ),
            type: "is-danger",
          });
          this.clickRequired = !value;
        })
        .finally(() => (this.loadingToggleClick = false));
    },
  },
  created() {
    this.fetchAllSettings();
  },
};
</script>

<style></style>
