var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_vm._t("beforeFields"),_c('ValidationProvider',{attrs:{"rules":"required","vid":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('registration.label.username'),"message":errors,"type":{ 'is-danger': errors[0], '': valid },"custom-class":_vm.labelClass}},[_c('b-input',{attrs:{"icon":"account","placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('registration.label.password'),"message":errors,"type":{ 'is-danger': errors[0], '': valid },"custom-class":_vm.labelClass}},[_c('b-input',{attrs:{"type":"password","icon":"lock","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|confirmed:password","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('registration.label.confirm_password'),"message":errors,"type":{ 'is-danger': errors[0], '': valid },"custom-class":_vm.labelClass}},[_c('b-input',{attrs:{"type":"password","icon":"lock","placeholder":"Password","lazy":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)}),_vm._t("afterFields"),_c('b-button',{staticClass:"is-primary mt-3",attrs:{"native-type":"submit"}},[_vm._t("buttonLabel",[_vm._v(" "+_vm._s(_vm.$t("registration.buttons.confirm"))+" ")])],2)],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }