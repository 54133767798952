<template>
  <section class="is-overflow-unset">
    <!-- <b-loading :active="isLoading" :is-full-page="false"></b-loading> -->

    <section class="section has-background-eurotext-light">
      <div class="container">
        <b-field grouped group-multiline>
          <b-field v-for="(filter, name) in filters" :key="name" expanded>
            <template #label
              ><span class="has-text-light">{{
                $t(`policies.rao.${filter.label}.label`)
              }}</span></template
            >
            <p class="control">
              <span class="button is-static" v-html="filter.symbol"></span>
            </p>

            <b-input
              v-if="name === 'subNickname'"
              v-model="filter.value"
              expanded
            ></b-input>

            <b-input
              v-else-if="name === 'fiscalCode'"
              :value="filter.value"
              expanded
              @input="(val) => (filter.value = val.toUpperCase())"
            ></b-input>

            <b-input
              v-else
              :value="filter.value"
              expanded
              @input="
                (val) =>
                  (filter.value = val.replace(
                    /\b\w+/g,
                    (txt) =>
                      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  ))
              "
            ></b-input>
          </b-field>
        </b-field>

        <div class="buttons pt-5">
          <b-button
            type="is-primary"
            native-type="button"
            icon-left="magnify"
            @click="searchUsers"
          >
            {{ $t("buttons.search") }}
          </b-button>
          <!-- <b-button type="is-primary" native-type="reset" icon-left="eraser">
              {{ $t("buttons.erase") }}
            </b-button> -->
        </div>
      </div>
    </section>

    <section class="section">
      <div class="mb-5">
        <b-button
          type="is-primary"
          native-type="button"
          icon-left="update"
          @click="searchUsers"
        >
          {{ $t("buttons.refresh") }}
        </b-button>
      </div>

      <document-table
        :data="tableData"
        :tableColumns="tableColumns"
        :isLoadingRows="loadingUsers"
        :perPage="perPage"
        :totalRecords="totalElems"
        :checkable="false"
        :clickable="true"
        :defaultOrderedColumnsNames="['_status_color', 'status']"
        @page="onTablePage($event)"
        @row-click="openUserDetail"
      >
        <template #cell="{ cellData, column, row }">
          <span v-if="column.field === 'status'" class="has-text-weight-bold">{{
            $t(`policies.rao.status.${cellData}`)
          }}</span>
          <span v-else-if="column.field === '_status_color'">
            <b-icon
              icon="circle"
              size="is-large"
              :type="`is-user-${row['status']}`"
            ></b-icon>
          </span>
          <span v-else-if="column.field === 'userRegistered'">
            <b-icon
              :icon="cellData ? 'check' : 'close'"
              :type="cellData ? 'is-success' : 'is-danger'"
              size=""
            ></b-icon>
          </span>
        </template>
      </document-table>
    </section>
  </section>
</template>

<script>
import DocumentTable from "../../components/DocumentTable/DocumentTable";
import { raoServices } from "../../services";
import { commonMixin } from "@/mixins";

export default {
  components: { DocumentTable },
  mixins: [commonMixin],
  data() {
    return {
      loadingUsers: false,
      filters: {
        subNickname: {
          value: null,
          symbol: "~",
          label: "username",
        },
        name: {
          value: null,
          symbol: "=",
          label: "name",
        },
        surname: {
          value: null,
          symbol: "=",
          label: "surname",
        },
        fiscalCode: {
          value: null,
          symbol: "=",
          label: "fiscalCode",
        },
      },
      tableData: [],
      page: 1,
      perPage: 20,
      totalElems: 0,
    };
  },
  computed: {
    tableColumns() {
      const el = this.tableData[0];
      if (el) {
        const columns = Object.keys(el)
          .filter((key) => key !== "id")
          .map((prop) => {
            const obj = {
              label: this.$t(`policies.rao.${prop}.label`),
              field: prop,
              headerClass: "_",
              sortable: false,
            };
            if (obj.field === "status") {
              obj.cellClass = "v-align-middle";
            }
            if (obj.field === "userRegistered") {
              obj.cellClass = "v-align-middle";
              obj.centered = true;
            }
            return obj;
          });
        columns.push({
          label: this.$t("einvoice.status.color.label"),
          field: "_status_color",
          centered: true,
          sortable: false,
          headerClass: "width-fit",
          cellClass: "v-align-middle",
        });
        return columns;
      } else {
        return [];
      }
    },
  },
  methods: {
    async searchUsers() {
      try {
        this.loadingUsers = true;
        const fil = Object.fromEntries(
          Object.keys(this.filters)
            .filter((key) => !!this.filters[key].value)
            .map((key) => [key, this.filters[key].value])
        );
        const res = await raoServices.fetchUsers(fil, this.page, this.perPage);
        this.tableData = res["users"];
        this.totalElems = res["total_results"];
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingUsers = false;
      }
    },
    onTablePage(event) {
      this.page = event.page;
      this.perPage = event.rows;
      this.searchUsers();
    },
    openUserDetail(user) {
      const userId = user.id;
      const tab = {
        id: userId,
        component: "UserDetail",
        label: `${user.username}`,
        icon: this.$config.icons.tabs.user_detail,
        props: {
          userId,
          companyName: this.companyName,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
  },
};
</script>

<style></style>
