<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(changePassword)">
        <slot name="beforeFields"></slot>
        <ValidationProvider
          rules="required"
          vid="username"
          v-slot="{ errors, valid }"
        >
          <b-field
            :label="$t('registration.label.username')"
            :message="errors"
            :type="{ 'is-danger': errors[0], '': valid }"
            :custom-class="labelClass"
          >
            <b-input
              v-model="username"
              icon="account"
              placeholder="Username"
            ></b-input>
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          rules="required"
          vid="password"
          v-slot="{ errors, valid }"
        >
          <b-field
            :label="$t('registration.label.password')"
            :message="errors"
            :type="{ 'is-danger': errors[0], '': valid }"
            :custom-class="labelClass"
          >
            <b-input
              v-model="password"
              type="password"
              icon="lock"
              placeholder="Password"
            ></b-input>
          </b-field>
        </ValidationProvider>
        <ValidationProvider
          name="confirm"
          rules="required|confirmed:password"
          vid="confirmation"
          v-slot="{ errors, valid }"
        >
          <b-field
            :label="$t('registration.label.confirm_password')"
            :message="errors"
            :type="{ 'is-danger': errors[0], '': valid }"
            :custom-class="labelClass"
          >
            <b-input
              v-model="confirmPassword"
              type="password"
              icon="lock"
              placeholder="Password"
              lazy
            ></b-input>
          </b-field>
        </ValidationProvider>

        <slot name="afterFields"></slot>

        <b-button native-type="submit" class="is-primary mt-3">
          <slot name="buttonLabel">
            {{ $t("registration.buttons.confirm") }}
          </slot>
        </b-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    labelClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: "",
      password: "",
      confirmPassword: "",
    };
  },
  watch: {},
  methods: {
    changePassword() {
      if (this.password && this.confirmPassword) {
        if (this.password === this.confirmPassword) {
          this.$emit("register", {
            username: this.username,
            password: this.password,
          });
          this.resetFields();

          // Wait until the models are updated in the UI
          this.$nextTick(() => {
            this.$refs.form.reset();
          });
        }
      }
    },
    resetFields() {
      this.username = "";
      this.password = "";
      this.confirmPassword = "";
    },
  },
};
</script>

<style></style>
