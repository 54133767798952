var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"is-overflow-unset"},[_c('section',{staticClass:"section has-background-eurotext-light"},[_c('div',{staticClass:"container"},[_c('b-field',{attrs:{"grouped":"","group-multiline":""}},_vm._l((_vm.filters),function(filter,name){return _c('b-field',{key:name,attrs:{"expanded":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"has-text-light"},[_vm._v(_vm._s(_vm.$t(("policies.rao." + (filter.label) + ".label"))))])]},proxy:true}],null,true)},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static",domProps:{"innerHTML":_vm._s(filter.symbol)}})]),(name === 'subNickname')?_c('b-input',{attrs:{"expanded":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):(name === 'fiscalCode')?_c('b-input',{attrs:{"value":filter.value,"expanded":""},on:{"input":function (val) { return (filter.value = val.toUpperCase()); }}}):_c('b-input',{attrs:{"value":filter.value,"expanded":""},on:{"input":function (val) { return (filter.value = val.replace(
                  /\b\w+/g,
                  function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }
                )); }}})],1)}),1),_c('div',{staticClass:"buttons pt-5"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"button","icon-left":"magnify"},on:{"click":_vm.searchUsers}},[_vm._v(" "+_vm._s(_vm.$t("buttons.search"))+" ")])],1)],1)]),_c('section',{staticClass:"section"},[_c('div',{staticClass:"mb-5"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"button","icon-left":"update"},on:{"click":_vm.searchUsers}},[_vm._v(" "+_vm._s(_vm.$t("buttons.refresh"))+" ")])],1),_c('document-table',{attrs:{"data":_vm.tableData,"tableColumns":_vm.tableColumns,"isLoadingRows":_vm.loadingUsers,"perPage":_vm.perPage,"totalRecords":_vm.totalElems,"checkable":false,"clickable":true,"defaultOrderedColumnsNames":['_status_color', 'status']},on:{"page":function($event){return _vm.onTablePage($event)},"row-click":_vm.openUserDetail},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
                var cellData = ref.cellData;
                var column = ref.column;
                var row = ref.row;
return [(column.field === 'status')?_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.$t(("policies.rao.status." + cellData))))]):(column.field === '_status_color')?_c('span',[_c('b-icon',{attrs:{"icon":"circle","size":"is-large","type":("is-user-" + (row['status']))}})],1):(column.field === 'userRegistered')?_c('span',[_c('b-icon',{attrs:{"icon":cellData ? 'check' : 'close',"type":cellData ? 'is-success' : 'is-danger',"size":""}})],1):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }